import {Component, Input} from '@angular/core';
import {TapNpayService} from './tap-n-pay.service';
import {TapNpayBookingDetails, TapNpayBookingInput} from "./tap-n-pay.class";

@Component({
    selector: 'app-tap-n-pay',
    template: `
        <cui-button (onClick)="initializeTapNpay()" [type]="'outlined'" [disabled]="disabled"
                    [leadingIcon]="disabled ? undefined : 'rfid'" [classes]="classes">
    <span class="fs-14">
        {{ disabled ? 'Set up your Tap & Pay' : 'Tap & Pay' }}
    </span>
        </cui-button>
    `
})
export class TapNpayComponent {

    @Input() disabled = false;
    @Input() classes = '';
    @Input() bookingDetailsInput: TapNpayBookingInput;

    constructor(private tapNpayService: TapNpayService) {}

    initializeTapNpay() {
        const accommodationTotal = this.bookingDetailsInput.accommodationTotal ? parseFloat(this.bookingDetailsInput.accommodationTotal.replace(/,/g, '')) : 0;
        const extraTotal = this.bookingDetailsInput.extraTotal ? parseFloat(this.bookingDetailsInput.extraTotal.replace(/,/g, '')) : 0;
        const paymentsTotal = this.bookingDetailsInput.paymentsTotal ? parseFloat(this.bookingDetailsInput.paymentsTotal.replace(/,/g, '')) : 0;

        const amountDue = Math.round((accommodationTotal + extraTotal - paymentsTotal) * 100)/100;

        const amount = 0;

        const bookingDetails: TapNpayBookingDetails = {
            amount,
            amountDue,
            accommodationTotal,
            extraTotal,
            paymentsTotal,
            bookingId: this.bookingDetailsInput.bookingid,
            bbid: this.bookingDetailsInput.bbid,
            clientFullName: this.bookingDetailsInput.clientFullName,
            fromDate: this.bookingDetailsInput.fromDate,
            toDate: this.bookingDetailsInput.toDate
        }

        this.tapNpayService.openAmountModal(bookingDetails);
    }

}
