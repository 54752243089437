import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PanelContainerComponent} from '../shared/panel/panel-container/panel-container.component';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

// Components
import {CheckoutPaymentComponent} from '../pages/booking-summary/checkout-payment/checkout-payment.component';
import {ExtraComponent} from '../pages/booking-summary/extra/extra.component';
import {ExtrasComponent} from '../pages/booking-summary/extras/extras.component';
import {RatesComponent} from '../pages/booking-summary/rates/rates.component';
import {RoomChangeComponent} from '../pages/booking-summary/room-change/room-change.component';
import {SourcesComponent} from '../pages/booking-summary/sources/sources.component';
import {ClientAddComponent} from '../pages/clients/client-add/client-add.component';
import {ClientHistoryComponent} from '../pages/clients/client-history/client-history.component';
import {ClientSearchComponent} from '../pages/clients/client-search/client-search.component';
import {ClientTransactionAddComponent} from '../pages/clients/client-transaction-add/client-transaction-add.component';
import {CompanySearchComponent} from '../pages/clients/company-search/company-search.component';

// Alt display components
import {BookingDetailsComponent} from '../pages/booking-details/booking-details.component';
import {MarketingSourceSearchComponent} from '../components/booking-info/widgets/marketing-source-search/marketing-source-search.component';
import {SimpleModalComponent} from '@shared/simple-modal/simple-modal.component';
import {ViewExtraComponent} from '../components/booking-info/widgets/view-extra/view-extra.component';
import {AddExtraComponent} from '../components/booking-info/widgets/add-extra/add-extra.component';
import {ClientsSearchComponent} from '../components/booking-info/widgets/clients-search/clients-search.component';
import {AddRoomComponent} from '../components/booking-info/widgets/add-room/add-room.component';
import {StatusUntilComponent} from '../components/booking-info/widgets/status-until/status-until.component';
import {ChangeRoomComponent} from '../components/booking-info/widgets/change-room/change-room.component';
import {EditClientComponent} from '../pages/booking-details/widgets/edit-client/edit-client.component';
import {EditClientTransactionsAddComponent} from '../components/booking-info/widgets/edit-client-transactions-add/edit-client-transactions-add.component';
import {PaymentComponent} from '../pages/accounts/payment/payment.component';
import {EditClientPaymentsComponent} from '../components/booking-info/widgets/edit-client-payments/edit-client-payments.component';
import {AccountSummaryComponent} from "../components/account-info/widgets/account-summary/account-summary.component";
import {AccountProFormaInvoiceComponent} from '../components/account-info/widgets/account-summary/account-pro-forma-invoice/account-pro-forma-invoice.component';
import {EditClientCompanySearchComponent} from '../components/booking-info/widgets/edit-client-company-search/edit-client-company-search.component';
import {CreateStatementComponent} from "../pages/accounts/create-statement/create-statement.component";
import {TransactionStatementComponent} from '../components/account-info/widgets/account-summary/transaction-statement/transaction-statement.component';
import {ViewRatesComponent} from "../components/account-info/widgets/view-rates/view-rates.component";
import {TransactionsComponent} from '../pages/clients/transactions/transactions.component';
import {RetireClientComponent} from '../components/booking-info/widgets/retire-client/retire-client.component';
import {AccountsComponent} from "../pages/accounts/accounts.component";
import {AccountItemActionsComponent} from '../components/account-info/widgets/account-summary/account-item-actions/account-item-actions.component';
import {AccountMoveItemComponent} from '../components/account-info/widgets/account-summary/account-move-item/account-move-item.component';

export enum DisplayMode {
  Panel = 'panel',
  Modal = 'modal',
  PageModal = 'pageModal'
}

@Injectable({
  providedIn: 'root'
})
export class PanelContainerService {
  private panelInstance: PanelContainerComponent;
  private modalInstance: NgbModalRef = undefined;

  constructor(private router: Router, private modalService: NgbModal) { }

  setPanelContainerInstance(instance: PanelContainerComponent): void {
    this.panelInstance = instance;
  }

  closePanel() {
    if (this.panelInstance) {
      this.panelInstance.closeTopPanel();
    }
  }

  closeAllPanels() {
    if (this.panelInstance) {
      this.panelInstance.closeAllPanels();
    }
  }

  hasOpenPanels(): boolean {
    if (this.panelInstance) {
      return this.panelInstance.isAnyPanelOpen;
    }
    return false;
  }

  openPanel(url: any, instanceParams?: any, state?: any, config?: {position?: 'left' | 'right', isFullScreen?: boolean}): void {
    if (!this.panelInstance) {
      console.error('PanelContainerComponent instance not set.');
      return;
    }

    let subPath = url[1] ? url[1].path : '';
    let component;
    let componentParams = {};
    let bookingId = '';
    let accountId = '';
    let displayMode: DisplayMode = DisplayMode.Panel;

    switch (url[0].path) {
      case 'booking-summary':
        bookingId = url[1] ? url[1].path : '';
        if (!bookingId) {
          this.router.navigate(['/calendar']);
          break;
        }
        subPath = url[2] ? url[2].path : '';

        switch (subPath) {
          case 'room-change':
            displayMode = DisplayMode.Modal;
            component = ChangeRoomComponent;
            break;
          case 'room-add':
            displayMode = DisplayMode.Modal;
            component = AddRoomComponent;
            break;
          case 'status-until':
            displayMode = DisplayMode.Modal;
            component = StatusUntilComponent;
            break;
          case 'rates':
            displayMode = DisplayMode.PageModal;
            component = ViewRatesComponent;
            break;
          case 'extras':
            displayMode = DisplayMode.Modal;
            component = ViewExtraComponent;
            break;
          case 'extra':
            displayMode = DisplayMode.Modal;
            component = AddExtraComponent;
            break;
          case 'checkout-payment':
            component = CheckoutPaymentComponent;
            break;
          case 'sources':
            displayMode = DisplayMode.Modal;
            component = MarketingSourceSearchComponent;
            break;
          default:
            component = BookingDetailsComponent;
            break;
        }

        componentParams = {
          bookingid: bookingId,
          options: state && state
        };
        break;

      case 'clients':
        switch (subPath) {
          case 'search':
            displayMode = DisplayMode.Modal;
            component = ClientsSearchComponent;
            break;
          case 'add':
          case 'edit':
            displayMode = DisplayMode.PageModal;
            component = EditClientComponent;
            break;
          case 'company-search':
            displayMode = DisplayMode.Modal;
            component = EditClientCompanySearchComponent;
            break;
          case 'retire':
            displayMode = DisplayMode.Modal;
            component = RetireClientComponent;
            break;
          case 'history':
            component = ClientHistoryComponent;
            break;
          case 'add-transaction':
            displayMode = DisplayMode.Modal;
            component = EditClientTransactionsAddComponent;
            break;
          case 'transactions':
            const extraPath = url[3] ? url[3].path : '';
            switch (extraPath) {
              case '':
                component = TransactionsComponent;
                break;
              default:
                displayMode = DisplayMode.PageModal;
                component = AccountProFormaInvoiceComponent;
                component.title = 'Invoice Preview';
                if (!state) {
                  state = {};
                }
                for (const param in instanceParams) {
                  state[param] = Number.isNaN(parseInt(instanceParams[param])) ? instanceParams[param] : parseInt(instanceParams[param]);
                }

                break;
            }
            break;
        }

        state = {
          ...state,
          title: component.title,
          buttons: component.buttons,
          buttonStateChange: component.buttonStateChange,
          titleStateChange: component.titleStateChange,
          onCloseOptions: component.onCloseOptions,
          backdropIllustration: component.backdropIllustration
        };

        componentParams = {
          options: state && state
        };
        break;

      case 'payment':
        switch (subPath) {
          case 'payment-actions':
            displayMode = DisplayMode.Modal;
            component = AccountItemActionsComponent;
            break;

          case 'move-payment':
            displayMode = DisplayMode.Modal;
            component = AccountMoveItemComponent;
            break;

          default:
            displayMode = DisplayMode.PageModal;
            component = EditClientPaymentsComponent;
        }


        state = {
          ...state,
          title: component.title,
          buttons: component.buttons,
          options: component.options
        };

        componentParams = {
          options: state && state
        };

        break;

      case 'account-search':
        subPath = url[1] ? url[1].path : '';
        switch (subPath) {
          case 'account-detail': {
            displayMode = DisplayMode.PageModal;

            accountId = url[2] ? url[2].path : '';
            const extraPath = url[3] ? url[3].path : '';

            switch (extraPath) {
              case 'proforma':
                component = AccountProFormaInvoiceComponent;
                component.title = 'Pro Forma Preview';
                break;

              case 'invoice':
                component = AccountProFormaInvoiceComponent;
                component.title = 'Invoice Preview';
                break;

              default:
                component = AccountSummaryComponent;
            }
          }
            break;

          case 'create-statement': {
            displayMode = DisplayMode.Modal;
            accountId = url[2] ? url[2].path : '';
            component = TransactionStatementComponent;
            break;
          }

          default: {
            displayMode = DisplayMode.Modal;
            component = AccountsComponent;
            component.title = 'Account Search';
            break;
          }

        }

        state = {
          ...state,
          title: component.title,
          buttons: component.buttons,
          accountId: accountId
        };

        componentParams = {
          options: state && state
        };

        break;
    }

    switch (displayMode) {
      case DisplayMode.Modal:
        this.openResponsiveModal(component, bookingId, state);
        break;
      case DisplayMode.PageModal:
        this.openResponsiveModal(component, bookingId, state, {backdropClass: 'page-modal-backdrop'});

        break;
      case DisplayMode.Panel:
      default:
        this.panelInstance.openComponent(component, componentParams, config);
        break;
    }
  }

  openResponsiveModal(
    component: any,
    bookingId: string,
    state: any,
    ngbModalOptionsOverride?: Partial<NgbModalOptions>
  ) {
    const defaultNgbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      fullscreen: 'sm',
      modalDialogClass: 'modal-decorator'
    };
    const ngbModalOptions = {...defaultNgbModalOptions, ...ngbModalOptionsOverride};

    this.modalInstance = this.modalService.open(SimpleModalComponent, ngbModalOptions);
    this.modalInstance.componentInstance.prepareContent(
      component, {bookingid: bookingId, options: state});
  }
}
