import {Component, Input, OnInit} from '@angular/core';
import {BridgeITResponse} from '@core/models';
import {ToastService} from '@nightsbridge/common-ui/toasts';
import {SimpleModalCloseOptions} from '@shared/simple-modal/simple-modal.contract';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {LoadingService} from 'src/app/service/loading.service';
import {Account} from 'src/app/service/models/Account';
import {Client} from 'src/app/service/models/Client';
import {Items} from 'src/app/service/models/Items';
import {Payment} from 'src/app/service/models/Payment';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PaymentService} from 'src/app/service/payment.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-account-move-item',
  templateUrl: './account-move-item.component.html',
  styleUrls: ['./account-move-item.component.scss']
})
export class AccountMoveItemComponent implements OnInit {

  @Input() title = 'List of Accounts';
  @Input() options;

  item: Items;
  client: Client;
  account: Account;
  payment: Payment;
  clientAccounts: any[] = [];

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'backAndClose',
  };

  constructor(private pageRouteService: PageRouteService,
    private paymentService: PaymentService,
    public translateService: TranslateService,
    private authService: CredentialService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    if (!this.options.item || !this.options.client || !this.options.account || !this.options.clientAccounts) {
      this.pageRouteService.close();
    }
    this.item = this.options?.item;
    this.client = this.options?.client;
    this.account = this.options?.account;
    this.payment = this.options?.payment;
    this.clientAccounts = this.options?.clientAccounts;
  }

  moveToAccount(account) {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('accountSummary', 'confirmPaymentMove'),
      buttons: [{
        text: 'no',
        class: this.isAltComponentDisplayActive ? 'btn-cancel min-w-120' : 'btn__warning',
        wrappingClass: 'flex-1',
        callback: () => {
        }
      }, {
        text: 'yes',
        class: this.isAltComponentDisplayActive ? 'btn-success min-w-120' : 'btn__green',
        wrappingClass: 'flex-1',
        callback: () => {
          this.loadingService.showLoadingScreen();
          this.payment.accountid = account.accountid;

          this.paymentService.editPayment(this.payment.paymentid, this.payment.reference, this.payment.paymenttype, this.payment.accountid).subscribe((response: BridgeITResponse) => {
            if (response.success) {
              this.loadingService.hideLoadingScreen();
              this.pageRouteService.navigate('payment/move-payment', {}, ['account-search/account-detail/' + this.account.accountid], this.options);
              this.toastService.show('Successful', 'Successfully moved to another account', {classname: 'toast-success', delay: 4000});
            } else {
              this.loadingService.hideLoadingScreen();
              this.toastService.show('Error', 'Could not moved item to another account', {classname: 'toast-error', delay: 4000});
            }
          });
        }
      }]
    });
  }

  private get isAltComponentDisplayActive() {
    const user = this.authService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }

}
