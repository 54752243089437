import {ChangeDetectorRef, Component, Input, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SimpleModalCloseOptions, SimpleModalContent} from './simple-modal.contract';
import {PageRouteService} from 'src/app/service/page-route.service';
import {NightsbridgeIconsId} from 'nightsbridge-icons/nightsbridge-icons';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss']
})
export class SimpleModalComponent implements SimpleModalContent {
  title: string;
  buttons: {
    id?: any;
    text: string;
    class: string;
    hidden?: boolean;
    disabled?: boolean;
    leadingIcon?: NightsbridgeIconsId;
    wrappingClass?: string
    callback: () => void;
  }[];
  onCloseOptions = {
    onModalClose: 'close'
  } as SimpleModalCloseOptions;
  backdropIllustration?: string;

  @Input() options: any;
  @ViewChild('bodyContent', {read: ViewContainerRef}) bodySlot: ViewContainerRef;

  private bodyComponent: Type<any>;
  public inputData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private pageRouteService: PageRouteService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.injectContent();
  }

  prepareContent(bodyComponent?: Type<any>, inputData?: any) {
    this.bodyComponent = bodyComponent;
    this.inputData = inputData;
    this.options = inputData.options;
  }

  private injectContent() {
    if (this.bodyComponent) {
      const bodyRef = this.bodySlot.createComponent(this.bodyComponent);

      if (bodyRef?.instance?.title || (this.inputData?.options && this.inputData?.options?.title)) {
        this.title = bodyRef.instance.title || this.inputData?.options?.title;
      };

      if (bodyRef?.instance?.backdropIllustration || (this.inputData?.options && this.inputData?.options?.backdropIllustration)) {
        this.backdropIllustration =  this.inputData?.options?.backdropIllustration || bodyRef.instance.backdropIllustration;
      };

      this.buttons = bodyRef.instance.buttons || this.inputData?.options?.buttons;
      this.onCloseOptions = bodyRef.instance.onCloseOptions || this.inputData?.options?.onCloseOptions;
      if (this.options?.footerFlexboxAlignment || bodyRef?.instance?.footerFlexboxAlignment) {
        this.options.footerFlexboxAlignment = this.options?.footerFlexboxAlignment ? this.options?.footerFlexboxAlignment : bodyRef?.instance?.footerFlexboxAlignment
      };
      Object.assign(bodyRef.instance, this.inputData);

      if (bodyRef.instance.buttonStateChange) {
        bodyRef.instance.buttonStateChange.subscribe(({id, ...changes}) => {
          if (id) {
            this.buttons = this.buttons.map(button => {
              if (button.id === id) {
                return {...button, ...changes};
              }
              return button;
            });
          } else {
            this.buttons = this.buttons.map(button => ({...button, ...changes}));
          }
        });
      }

      if (bodyRef.instance.titleStateChange) {
        bodyRef.instance.titleStateChange.subscribe((title) => {
          this.title = title;
        });
      }
      
      if (bodyRef.instance.closeOptionsChange) {
        bodyRef.instance.closeOptionsChange.subscribe((onCloseOptions) => {
          this.onCloseOptions = onCloseOptions;
        });
      }
    }
    this.cdr.detectChanges();
  }

  close() {
    if (this.onCloseOptions?.onModalClose === 'back') {
      this.pageRouteService.back(this.onCloseOptions.returnData);
    }  else if (this.onCloseOptions?.onModalClose === 'backAndClose') {
      this.activeModal.close();
      this.pageRouteService.back(this.onCloseOptions.returnData);
    } else if (this.onCloseOptions?.onModalClose === 'refresh') {
      window.location.reload();
    } else {
      this.activeModal.close();
    }
  }

  buttonClick(button) {
    button.callback();

    if (!this.onCloseOptions?.preventAutoClose) {
      this.activeModal.close();
    }
  }
}
