<div class="px-3 pb-3" *ngIf="payment">
  <div class="d-flex flex-column color-neutral p-3 rounded">
    <small><strong>Payment ID:</strong> {{payment.paymentid}}</small>
    <small><strong>Date</strong> {{payment.paymentdate}}</small>
    <small><strong>Reference</strong> {{payment.reference}}</small>
  </div>

  <div class="d-flex pt-1 fs-14">
    <div class="btn-action border rounded m-1 text-center d-flex align-items-center p-2"
      (click)="editPaymentReference()">
      Edit payment reference
    </div>
    <div class="btn-action border rounded m-1 text-center d-flex align-items-center p-2" (click)="unallocateItem()">
      Move to unallocated account
    </div>
    <div class="btn-action border rounded m-1 text-center d-flex align-items-center p-2" (click)="moveItem()"
      *ngIf="clientAccounts.length > 1">
      Move to a different account with the same client
    </div>
  </div>
</div>