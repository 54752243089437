import {Injectable} from '@angular/core';
import {CredentialService} from "./credential.service";
import {CurrencyPipe} from "./currency.pipe";

@Injectable({
  providedIn: 'root'
})
export class NbLibraryService {
  private formatter: Intl.NumberFormat;

  constructor(private credentialService: CredentialService) {
    const langauge = window.navigator.language === undefined ? 'en-GB' : window.navigator.language
    this.formatter = new Intl.NumberFormat(langauge, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: this.credentialService.getCurrency, //this is a default, but needs to be overriden.
    });
  }

  public urlParamsToString(URLParams: any): string {
    let paramString = '';
    for (const c in URLParams) {
      if (paramString !== '') {
        paramString += '&';
      }
      paramString += c + '=' + encodeURIComponent(URLParams[c]);
    }
    return paramString;
  }

  formatNumber(s, separator) {
    if (typeof s !== 'string') {
      s = String(s);
    }
    const a = s.split('.');
    let n = '';
    const l = a[0].length;
    for (let i = a[0].length - 1; i >= 0; i--) {
      n = a[0][i] + n;
      if (((l - i) % 3 === 0) && (i !== 0)) {
        n = separator + n;
      }
    }
    return n + '.' + a[1];
  }

  formatCurrency(value: string | number) {
    if (isNaN(Number(value))) {
      return value as string;
    }
    return this.formatter.format(Number(value));
  }

  getNumberFromCurrency(amount: any) {
    let amountValue = this.formatCurrency(amount);
    let commaRemoval = amountValue.replace('.','').replace(',','');
    let spaceRemoval = commaRemoval.replace(/\s/g, '');

    return Number(spaceRemoval) / 100;
  }

  cleanAndParseNumber(amount: string){
    return amount && !isNaN(Number(amount)) ? parseFloat(amount.replace(/,/g, '')) : 0
  }

}
