<div class="d-flex flex-column flex-sm-row w-100 gap-3 gap-sm-0">
    <div class="preview-wrapper flex-2">
        <iframe class="shadow-lg preview-box" #proformaIframe id="proformaIframe" [src]="html | safe: 'resourceUrl'">
            <p>{{'general' | translate: 'error'}}</p>
        </iframe>
    </div>
    <div class="d-flex flex-1 flex-sm-column p-1 pe-3 me-2 flex-column ms-3 ms-sm-0">
        <form [formGroup]="form">
            <span class="fw-semi-bold fs-16 mb-3">{{'accountSummary' | translate: 'proformaEmailedTo'}}</span>
            <cui-input type="text" name="email" formControlName="email" class="w-100" classes="mb-3" label="Email"
                [required]="true" [sizing]="'md'" placeholder="Enter email"></cui-input>
            <span class="text__grey-light mb-2 fs-14 fw-normal">{{'accountSummary' | translate:
                'additionalEmails'}}</span>
            <cui-input type="checkbox" [label]="'accountSummary' | translate: 'bccMe'"
                class="w-100 me-2 mb-3 d-flex justify-content-start justify-content-sm-center"
                classes="email-input mt-4" formControlName="checkedin"
                (change)="updateCheckboxValue(form.get('checkedin').value)"
                classes="mb-1 mt-2 fs-14 d-flex align-items-end">
            </cui-input>
            <button class="btn__icon btn__send-email w-100 h-48 mb-3 rounded" (click)="sendEmail()"
                [disabled]="form.invalid">Send
                Email</button>
            <button class="btn__icon btn__print w-100 h-48 rounded" (click)="print()">Print</button>
        </form>
    </div>
</div>