<div class="px-3 pb-3">
  <div class="fw-semibold pb-3">Choose an account for {{client.fullname}}</div>

  <div class="table-scrollable-md overflow-y-auto">
    <table class="table table-striped table-hover table-responsive border-top max-h-560 w-600 border rounded">
      <thead class="border-bottom h-45">
        <tr>
          <th scope="col" class="fw-semibold">A/C IDs</th>
          <th scope="col" class="fw-semibold">Account Details:</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let account of clientAccounts; let i = index" >
          <tr *ngIf="account.details.isbooking" (click)="moveToAccount(account)">
            <td class="typography__p text-start">{{account.accountid}}</td>
            <td class="typography__p text-start">Booking ID - {{account.details.bbbookingid}}</td>
          </tr>
          <tr *ngIf="!account.details.isbooking" (click)="moveToAccount(account)">
            <td class="typography__p text-start">{{account.accountid}}</td>
            <td class="typography__p text-start">Extra - {{account.details.extradate}} ({{account.details.qty}}x
              {{account.details.stockname}})</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>