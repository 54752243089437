<div class="p-3">
    <app-account-summary-details
        [account]="account"
        [items]="items"
        [client]="client"
        [breakpoints]="breakpoints"
        [pdfViewerActive]="pdfViewerActive"
        [constants]="constants"
        [invoiceTemplatePreference]="invoiceTemplatePreference"
        (invoiceReprintWarning)="invoiceReprintWarning($event)"
        (downloadProForma)="downloadProForma()"
        (updateAccountStatus)="updateAccountStatus()"
        (editClient)="editClient()"
        (changeClient)="changeClient()"
        (createInvoice)="createInvoice($event)"
        (createProForma)="createProForma()"
        (openStatementModal)="openStatementModal()"
        ></app-account-summary-details>
    <app-account-summary-items-list 
        [items]="items"
        (viewItem)="viewItem($event)"
        (isMobile)="isMobile()"
        (addPayment)="addPayment()"
        (generateCcLink)="generateCcLink()"
    ></app-account-summary-items-list>
</div>

