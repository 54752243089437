import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';

import {PageRouteService} from 'src/app/service/page-route.service';
import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PaymentService} from 'src/app/service/payment.service';
import {PaymentTypes} from 'src/app/service/models/enum/payment-types.enum';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {Payment} from '../../../service/models/Payment';
import {NbLibraryService} from "../../../service/nb-library.service";
import {CurrencyPipe} from "../../../service/currency.pipe";
import {formatAmountAsDecimalNumber} from 'src/utils';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [CurrencyPipe]
})
export class PaymentComponent implements OnInit {
  @Input() options;

  saveClicked = false;
  payment: Payment = new Payment();
  addMode;
  focusAmount;
  paymenttypes;

  paymentAddForm = new FormGroup({
    paymentdate: new FormControl(undefined, Validators.required), // payment.paymentdate
    paymenttype: new FormControl(undefined, Validators.required), // payment.paymenttype
    amount: new FormControl(undefined, [
        Validators.required,
        Validators.pattern(/[^a-z]+$/i)
    ]), // payment.amount
    reference: new FormControl(undefined, Validators.maxLength(20)), // payment.reference
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private pageRouteService: PageRouteService,
    public translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private paymentService: PaymentService,
    private datelibraryService: DatelibraryService,
    private nbLib: NbLibraryService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    if (!this.options) {
      this.close();
    }
    
    this.payment = new Payment();

    this.paymenttypes =
      Object.values(PaymentTypes)
        .filter(value => typeof (value) === 'number')
        .map((value) => {
          return {
            paymenttype: value,
            paymenttypetext: this.translateService.translate('paymentTypes', String(value)),
          };
        });

    if (this.options?.accountid) {
      this.payment.accountid = this.options.accountid;
      let newDate = this.datelibraryService.getDateObject((this.datelibraryService.getDate()));
      this.payment.createdate = moment(newDate).format('YYYY-MM-DD');
      this.addMode = true;
      this.payment.paymentdate = newDate;
      this.focusAmount = true;
      this.payment.paymenttype = 2; // Default is Bank

      this.setFormValues();
    } else {
      let data: { [key: string]: any } = {};
      if (this.options.payment.tranno) {
        data = {
          bbpaymentid: this.options.payment.tranno
        };
      } else if (this.options.payment) {
        data = {
          paymentid: this.options.payment
        };
      }

      // Lets fetch the Base Payment
      this.paymentService.getPayment(data).subscribe((response: any) => {
        this.payment = response.data.payment;
        this.payment.paymenttype = parseInt(response.data.payment.paymenttype);

        this.setFormValues();
      });

      this.addMode = false;
    }
  }

  close(returnData?) {
    this.pageRouteService.back(returnData,true);
  }

  savePayment() {
    if (this.saveClicked) {
      return;
    }

    this.saveClicked = true;
    this.paymentAddForm.markAllAsTouched();

    

    if (this.paymentAddForm.valid && this.paymentAddForm.dirty) {
      this.getFormValues();
      if (this.addMode) {
        this.payment.amount = this.nbLib.cleanAndParseNumber(this.payment.amount);
        this.paymentService.addPayment(this.payment).subscribe((response: any) => {
          let data = response.data.payment;
          data.paymentmade = true;
          this.close(data);
        });
      } else {
        this.paymentService.editPayment(this.payment.paymentid, this.payment.reference, this.payment.paymenttype).subscribe((response: any) => {
          this.close(response.data.reference);
          this.saveClicked = false;
        });
      }

    } else if (!this.paymentAddForm.valid) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('payment', 'paymentEnterAmount'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
      this.saveClicked = false;
    } else {
      this.close(this.payment.reference);
    }
  }

  getFormValues() {
    this.payment.paymentdate = moment(this.paymentAddForm.value.paymentdate).format('YYYY-MM-DD');
    this.payment.amount = formatAmountAsDecimalNumber(this.paymentAddForm.value.amount);
    this.payment.reference = this.paymentAddForm.value.reference;

    // this is needed since disabling this input hides the value from `.value`
    if (this.addMode) {
      this.payment.paymenttype = this.paymentAddForm.value.paymenttype;
    }
  }

  setFormValues() {
    if (!this.addMode) {
      this.paymentAddForm.controls.paymenttype.disable();
    }

    this.paymentAddForm.patchValue({
      paymentdate: this.datelibraryService.getDateObject(this.payment.paymentdate),
      paymenttype: this.payment.paymenttype,
      amount: this.currencyPipe.transform(this.payment.amount),
      reference: this.payment.reference,
    });
  }

  debounce(funcFormat, func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  formatPrice() {
    this.paymentAddForm.get('amount').patchValue(this.currencyPipe.transform(this.paymentAddForm.get('amount').value));
  }

}
