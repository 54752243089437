import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {InjectionToken} from "@angular/core";

export interface IsPaymentTapEnabled {
    paymentTapEnabled: boolean
}

export interface TapNpayBookingInput {
    accommodationTotal: string;
    extraTotal: string;
    paymentsTotal: string;
    bookingid: number;
    bbid: number;
    clientFullName: string;
    fromDate: Date | string;
    toDate: Date | string;
}

export interface TapNpayBookingDetails {
    amount: number;
    amountDue: number;
    accommodationTotal: number;
    extraTotal: number;
    paymentsTotal: number;
    bookingId: number;
    bbid: number;
    clientFullName?: string;
    fromDate?: Date | string;
    toDate?: Date | string;
}


export interface TapNpayTransaction {
    userid: string;
    bbid: number;
    bookingId: number;
    amount: number;
    amountDue: number;
    clientFullName: string;
    fromDate: Date | string;
    toDate: Date | string;
    deviceId: string;
    deviceLabel: string;
    accommodationTotal: number;
    extraTotal: number;
    paymentsTotal: number;
}

export interface TapNpayTransactionPayload {
    userid: string;
    bbid: number;
    bookingId: number;
    amount: number;
    amountDue: number;
    clientFullName: string;
    fromDate: Date | string;
    toDate: Date | string;
    clientId: string;
    source?: TapNpaySource;
    messagename?: string;
    credentials?: {loginkey: string};
    deviceId: string;
    deviceLabel: string;
    accommodationTotal: number;
    extraTotal: number;
    paymentsTotal: number;
}

export interface TapNpayTransactionResponse {
    clientId: string,
    paymentId: string,
    deviceId: string,
    bookingId: string,
    transactionId: string,
    transactionDate: string,
    status: string,
    amount: number,
    action: string
}

export interface TapNpayTransaction {
    amount: number;
    amountDue: number;
    bookingID: number;
    clientFullName: string;
    fromDate: Date | string;
    toDate: Date | string;
    transactionDate: Date;
    transactionID: string;
    paymentID: string;
}

export interface TapNpayProccesPaymentResponse { data: { paymentId: string; status: boolean }; success: boolean; };

export interface TapNpayHttpClient {
    getRequest<T>(url: string | string[], params?: object): Observable<T> | undefined;
    postRequest<T>(url: string | string[], data: object): Observable<T> | undefined;
    putRequest<T>(url: string | string[], data: object): Observable<T> | undefined;
    deleteRequest<T>(url: string | string[], params?: object): Observable<T> | undefined;
    get?: HttpClient['post'];
    post?: HttpClient['post'];
    put?: HttpClient['put'];
    delete?: HttpClient['delete'];
}

export type TapNpayDevicesResponse = { data:{ devices: TapNpayDevice[] }};

export type TapNpayDevice = { deviceId: string, state: TapNpayDeviceStatus };

export type TapNpaySource = 'bridgeit' | 'nightsbridge';

export type TapNpayQueryMessage = 'PaymentTapRQ' | 'PaymentTapEnabledRQ' | 'DeviceManagementRQ' | 'TimeoutPaymentTapRQ????';

export type TapNpayDeviceStatus = 'EVT_DEVICE_POWER_UP' | 'EVT_DEVICE_POWER_DOWN' | 'EVT_DEVICE_STATUS_DISABLED' |
    'EVT_DEVICE_STATUS_ENABLED' | 'EVT_DEVICE_STATUS_PROCESSING' | 'EVT_DEVICE_STATUS_PROCESSING_DONE' | 'DEVICE_STATUS_ENABLED';

export const TAP_N_PAY_ENVIRONMENT_TOKEN = new InjectionToken('TAP_N_PAY_ENVIRONMENT');
export const TAP_N_PAY_HTTP_TOKEN = new InjectionToken('TAP_N_PAY_HTTP');
