import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from 'src/app/service/translate.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {Items} from 'src/app/service/models/Items';
import {DisplayManagerService} from 'src/app/service/display-manager.service';

@Component({
  selector: 'app-account-summary-items-list',
  templateUrl: './account-summary-items-list.component.html',
  styleUrls: ['./account-summary-items-list.component.scss']
})
export class AccountSummaryItemsListComponent {

  @Input() items;
  @Output() viewItem = new EventEmitter();
  @Output() addPayment = new EventEmitter();
  @Output() generateCcLink = new EventEmitter();

  constructor(public translateService: TranslateService, private displayManagerService: DisplayManagerService) { }

  getAmount(amount: any) {    
    if (Number(amount) === 0) { return "-"; }
    return amount;
  }

  getToolTipForAccountItem(item: Items) {
    if (item.itemtype === Constants.LINE_ITEM_PAYMENT) {
      return this.translateService.translate('accountSummary', 'lineItemPaymentTooltip');
    } else if (item.itemtype === Constants.LINE_ITEM_CAPS_EXTRA) {
      return this.translateService.translate('accountSummary', 'lineItemCapsExtraTooltip');
    } else {
      return this.translateService.translate('accountSummary', 'lineItemTooltip');
    }
  }

  isMobile() {
    return this.displayManagerService.isMobile();
  }

}
