import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './service/helper/auth-guard.service';
import {LoginComponent} from 'src/app/pages/login/login.component';
import {MainPageComponent} from 'src/app/pages/main-page/main-page.component';
import {DisplayManagerComponent} from './display-manager.component';

const managedRoutes = [
  'reports',
  'account-search/account-detail/:accountId',
  'account-search/account-detail/:accountId/:template/:templateno',
  'account-search/create-statement/:clientId',
  'client-account',
  'client-account/list',
  'account-search',
  'close-accounts',
  'clients/search',
  'clients/add',
  'clients/edit/:clientId',
  'clients/company-search',
  'clients/retire',
  'clients/history',
  'clients/transactions',
  'clients/transactions/:template/:templateno',
  'clients/add-transaction',
  'tools/cancellation',
  'tools/occupancy',
  'tools/double-booking',
  'tools/marketing',
  'tools/preference',
  'search',
  'booking-summary/:bookingId',
  'booking-summary/:bookingId/room-change',
  'booking-summary/:bookingId/room-add',
  'booking-summary/:bookingId/rates',
  'booking-summary/:bookingId/extras',
  'booking-summary/:bookingId/extra',
  'booking-summary/:bookingId/guest-communications',
  'booking-summary/:bookingId/checkout-payment',
  'booking-summary/:bookingId/sources',
  'booking-summary/:bookingId/status-until',
  'payment',
  'payment/payment-actions',
  'payment/move-payment',
  'events',
  'events/add',
  'events/edit/:eventId',
  'check-in-out',
  'add-booking',
  'my-properties',
  'swap-rooms',
  'quote',
  'checkout-payment',
  'change-user',
];

const managedChildRoutes: Routes = managedRoutes.map(path => ({
  path,
  component: DisplayManagerComponent
}));

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    canActivate: [AuthGuardService],
    children: [
      {path: '', redirectTo: '/calendar', pathMatch: 'full'},
      {path: 'calendar', component: MainPageComponent},
      ...managedChildRoutes,
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'loginkey/:loginkey', component: LoginComponent},
  {path: '**', redirectTo: 'calendar', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
